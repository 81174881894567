<template>
    <div style="text-align: left;">
        <el-card>
            <div class="top">
                <div class="top_left">
                    <el-button-group>
                        <el-button :type="memberCardUserTicketUsed===false?'primary':''" @click="setTicketIsPublish(false)">未使用</el-button>
                        <el-button :type="memberCardUserTicketUsed===true?'primary':''" @click="setTicketIsPublish(true)">已使用</el-button>
                        <!-- <el-button :type="effect===false?'primary':''" @click="setIsEffective(false)">未失效</el-button> -->
                        <el-button :type="effect===false?'primary':''" @click="setIsEffective(false)">已失效</el-button>
                    </el-button-group>
                </div>
            </div>
            <div class="m-t-15 df-jc-s-b">
                <div style="flex:1">
                    <div><span class="f20 c-1 bold">购物券</span><span class="f12 c-2">(数量：{{list1 && list1.length}}张)</span></div>
                    <div class="box">
                        <div class="items" v-if="list1 && list1.length">
                            <div class="item" v-for="(v,i) in list1" :key="i">
                                <!-- isUse到时候拿后台返回数据：目前写死0未使用 1已使用 2已过期(具体看后台) -->
                                <coupon4 :v="v" source="A" typeCoupon="1" :isUse="v.memberCardUserTicketUsed" :isOut="v.memberCardUserTicketExpired"></coupon4>
                            </div>
                        </div>
                        <div v-else>
                            <div class="notData" v-if="hasAjax==false">---- 暂无数据 ----</div>
                        </div>
                    </div>
                </div>
                <div style="flex:1">
                    <div><span class="f20 c-1 bold">现金券</span><span class="f12 c-2">(数量：{{list0 && list0.length}}张)</span></div>
                    <div class="box">
                        <div class="items" v-if="list0 && list0.length">
                            <div class="item" v-for="(v,i) in list0" :key="i">
                                <coupon4 :v="v" source="A" typeCoupon="0" :isUse="v.memberCardUserTicketUsed" :isOut="v.memberCardUserTicketExpired"></coupon4>
                            </div>
                        </div>
                        <div v-else>
                            <div class="notData" v-if="hasAjax==false">---- 暂无数据 ----</div>
                        </div>
                    </div>
                </div>
                <div style="flex:1">
                    <div><span class="f20 c-1 bold">物流券</span><span class="f12 c-2">(数量：{{list2 && list2.length}}张)</span></div>
                    <div class="box">
                        <div class="items" v-if="list2 && list2.length">
                            <div class="item" v-for="(v,i) in list2" :key="i">
                                <coupon4 :v="v" source="A" typeCoupon="2" :isUse="v.memberCardUserTicketUsed" :isOut="v.memberCardUserTicketExpired"></coupon4>
                            </div>
                        </div>
                        <div v-else>
                            <div class="notData" v-if="hasAjax==false">---- 暂无数据 ----</div>
                        </div>
                    </div>
                </div>
            </div>
        </el-card>
    </div>
</template>
<script>
import oucy from "@/util/oucyUtil";

import { ticket } from "@/service"
import { localSet, localGet, localDel } from "@/store/store"
export default {
    name: "create",
    data() {
        return {
            activeName: 'second',
            enterpriseId: null,
            memberCardUserTicketUsed: !true,
            effect: true,
            ticketByEnterprise: [],

            v: {}, //缓存优惠券
            visible: false,
            maxlength: -1,
            max: -1,
            ticketNum: 1,
            list0: [],
            list1: [],
            list2: [],
            hasAjax: true
        }
    },
    mounted() {
        let enterprise = localGet('enterprise')
        this.enterpriseId = enterprise.id

        this.getMemberCardUseTicketList()
    },
    methods: {
        handleClick(tab, event) {
            console.log(tab, event);
        },
        initList() {
            this.memberCardUserTicketUsed = null
            this.effect = null
            this.hasAjax = true
            this.ticketByEnterprise = []
            this.getMemberCardUseTicketList()
        },
        setTicketIsPublish(v) {
            if (this.memberCardUserTicketUsed === v) {
                return
                this.memberCardUserTicketUsed = null
            } else {
                this.memberCardUserTicketUsed = v
            }
            this.effect = true
            this.hasAjax = true
            this.ticketByEnterprise = []
            this.getMemberCardUseTicketList()
        },
        setIsEffective(v) {
            if (this.effect === v) {
                return
                this.effect = null
            } else {
                this.effect = v
            }
            this.memberCardUserTicketUsed = null
            this.hasAjax = true
            this.ticketByEnterprise = []
            this.getMemberCardUseTicketList()
        },
        getMemberCardUseTicketList() {
            this.list0= []
            this.list1= []
            this.list2= []
            // memberCardUserTicketUsed 是否使用
            // ticketKind  优惠券类型 0满减券 1现金券
            ticket.getMemberCardUseTicketList({ memberCardUserTicketUsed: this.memberCardUserTicketUsed, effect: this.effect }).then(res => {
                this.hasAjax = false
                this.ticketByEnterprise = res
                for (let v of res) {
                    if (v.memberCardUserTicketScheme == 0) {
                        this.list0.push(v)
                    } else if (v.memberCardUserTicketScheme == 1) {
                        this.list1.push(v)
                    } else if (v.memberCardUserTicketScheme == 2) {
                        this.list2.push(v)
                    }
                }
            })
        },

        openUpdateTicket(v, i) {
            this.v = v
            this.form.title = '修改:' + v.ticketName
            this.form.ticketPubTotal = 1
            this.form.ticketDeadTime = v.ticketDeadTime
            this.form.min = v.ticketPubTotal * 1 - v.ticketGetTotal * 1
            this.form.ticketId = v.id
            this.form.old_ticketDeadTime = v.ticketDeadTime
            this.distributionVisible = true
        },
        onSubmit() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this.form.ticketPubTotal = this.form.ticketPubTotal * 1
                    this.updateTicket()
                } else {
                    console.log('error submit!!');
                    return false;
                }
            })
        },
        updateTicket() {
            this.$oucy.confirm(`确定${this.form.title}？`).then(res => {
                ticket.updateTicket(this.form).then(res => {
                    this.v.ticketPubTotal = this.form.ticketPubTotal + this.v.ticketPubTotal
                    this.v.ticketDeadTime = this.form.ticketDeadTime
                    this.distributionVisible = false
                })
            }).catch(err => {})
        },
        // 打开选择用户
        openDialogFormVisible(v, i) {
            this.v = v
            this.maxlength = v.ticketPubTotal * 1 - v.ticketGetTotal * 1
            this.$refs.selectUserDialog.openDialogFormVisible()
        },
        // 选择用户回调
        callBack(list) {
            this.distributionVisible2 = true
            this.userIdList = list
            if (list.length > 1) {
                let names = ''
                for (let v of list) {
                    names += v.ltUserDetailFull.detailNickname + '；'
                }
                names += list.length + '位用户。'
            }
            this.max = ~~(this.maxlength / list.length)
            // this.enterApplyForm.userAuthId = v.id
            // this.selectObj = v
        },
    }
}
</script>
<style scoped lang="less">
.box {
    overflow: auto;
    max-height: 600px;
}

.top {
    display: flex;
    justify-content: space-between;
}

.top_Right {
    display: flex;
    align-items: flex-start;
}

.top_Right_box {
    border-radius: 10px;
    padding: 22px 32px;
    cursor: pointer;
}

.top_Right_box+.top_Right_box {
    margin-left: 10px;
}

.top_Right_box_title {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
}

.top_Right_box1 {
    background: #EAF3FF;

    .top_Right_box_title {
        color: #555C68;
    }
}

.top_Right_box2 {
    background: #FFF7EA;

    .top_Right_box_title {
        color: #E7801B;
    }
}

.top_Right_box3 {
    background: #FFF4F4;

    .top_Right_box_title {
        color: #F05353;
    }
}

.top_Right_box_info {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    margin-top: 10px;
}

.items {

    display: flex;
}

.items {
    flex-wrap: wrap;
}



.item {

    margin: 15px;
    /*margin-bottom: 50px;*/
}




.item_bottom {
    text-align: center;
    margin-top: 30px;
}


.head {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.center {
    flex: 1;
    margin-left: 5px;
}

.top {
    text-align: center;
}

.c-10 {
    color: #2090FF;
}

.notData {
    text-align: center;
    color: #ccc;
    padding: 125px 0;
    font-size: 14px;
}
</style>